import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Fragment } from "react";
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import Clients from "./Components/Pages/Clients";
import Services from "./Components/Pages/Services";
import Compliance from "./Components/Pages/Compliance";
import WhyUs from "./Components/Pages/WhyUs";
import SignIn from "./Components/Pages/SignIn";
import Admin from "./Components/Pages/Admin";
import { AuthProvider } from "./Auth/auth";
import PrivateRoute from "./Routing/PrivateRoute";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch className="App">
          <Fragment>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/clients" component={Clients} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/compliance" component={Compliance} />
            <Route exact path="/whyus" component={WhyUs} />
            <Route exact path="/signin" component={SignIn} />
            <PrivateRoute exact path="/admin" component={Admin} />
          </Fragment>
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
